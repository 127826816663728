<template>
  <navbar></navbar>

  <div class="main-container">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <h3>Change Password</h3>
      </div>
    </div>

    <div class="row margin-top-section">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>Existing Password</label>
          <input type="password" v-model="oldPassword" class="form-control"/>
        </div>
        <div class="form-group">
          <label>New Password</label>
          <input type="password" v-model="newPassword" class="form-control"/>
          <span v-show="newPassword.length > 0 && newPassword.length < 8" class="small red">
            Passwords must be at least 8 characters long.
          </span>
        </div>
        <div class="form-group">
          <label>Confirm Password</label>
          <input type="password" v-model="confirmPassword" class="form-control"/>
          <span
              v-show="confirmPassword.length > 0 &&
                      newPassword.length > 0 &&
                      newPassword != confirmPassword"
              class="small red">
            Passwords do not match</span>
        </div>
        <button type="button"
                @click="changePassword();"
                class="btn btn-primary wide-on-mobile">Change Password</button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/app/auth';
import Navbar from '@/components/Navbar.vue';
import userInfo from '@/app/user-info';
import utils from '@/app/utils';

export default {
  components: { Navbar },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  methods: {
    async changePassword() {
      // validation
      if (this.oldPassword.length === 0) {
        utils.showToastError('Existing password not specified',
          'Please specify your existing password to continue.', true);
        return;
      }

      if (this.newPassword.length < 8) {
        utils.showToastError('Invalid Password',
          'Passwords need to be at least 8 characters in length.', true);
        return;
      }

      if (this.newPassword !== this.confirmPassword) {
        utils.showToastError('Passwords do not match',
          'Please make sure the new password and confirm password fields match.',
          true);
        return;
      }

      try {
        await userInfo.changePassword(await auth.getUserIdToken(),
          this.oldPassword, this.newPassword, await auth.getAccessToken());
        utils.showToastSuccess('Password changed',
          'Your password was changed successfully.');

        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
      } catch {
        utils.showToastError('Could not change password',
          'Please make sure you are typing the correct existing password. <br />'
            + 'If this reoccurs, please refresh the page and try again.');
      }
    },
  },
};
</script>
